import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationAT from './locales/at/translation.json';
import translationCS from './locales/cs/translation.json';
import translationDA from './locales/da/translation.json';
import translationDE from './locales/de/translation.json';
import translationEL from './locales/el/translation.json';
import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';
import translationET from './locales/et/translation.json';
import translationFI from './locales/fi/translation.json';
import translationFR from './locales/fr/translation.json';
import translationHU from './locales/hu/translation.json';
import translationIE from './locales/ie/translation.json';
import translationIT from './locales/it/translation.json';
import translationJA from './locales/ja/translation.json';
import translationLT from './locales/lt/translation.json';
import translationLV from './locales/lv/translation.json';
import translationNL from './locales/nl/translation.json';
import translationNO from './locales/no/translation.json';
import translationPL from './locales/pl/translation.json';
import translationPT from './locales/pt/translation.json';
import translationRO from './locales/ro/translation.json';
import translationSK from './locales/sk/translation.json';
import translationSL from './locales/sl/translation.json';
import translationSV from './locales/sv/translation.json';
import translationTH from './locales/th/translation.json';
import translationTR from './locales/tr/translation.json';
import translationZH from './locales/zh/translation.json';

// primark translation override files
import translationPrimarkAT from './locales/at/primark.json';
import translationPrimarkCS from './locales/cs/primark.json';
import translationPrimarkDE from './locales/de/primark.json';
import translationPrimarkES from './locales/es/primark.json';
import translationPrimarkFR from './locales/fr/primark.json';
import translationPrimarkHU from './locales/hu/primark.json';
import translationPrimarkIT from './locales/it/primark.json';
import translationPrimarkNL from './locales/nl/primark.json';
import translationPrimarkPL from './locales/pl/primark.json';
import translationPrimarkPT from './locales/pt/primark.json';
import translationPrimarkRO from './locales/ro/primark.json';
import translationPrimarkSK from './locales/sk/primark.json';
import translationPrimarkSL from './locales/sl/primark.json';
import translationCirclekET from './locales/et/circlek.json';
import translationCirclekLT from './locales/lt/circlek.json';
import translationCirclekLV from './locales/lv/circlek.json';
import translationCirclekPL from './locales/pl/circlek.json';
import translationCirclekSV from './locales/sv/circlek.json';

// circlek translation override files
import translationCirclekEN from './locales/en/circlek.json';

// pandora translation override files
import translationPandoraEN from './locales/en/pandora.json';

// the translations
const resources = {
  at: {
    translation: translationAT,
    primark: translationPrimarkAT
  },
  cs: {
    translation: translationCS,
    primark: translationPrimarkCS
  },
  da: {
    translation: translationDA,
  },
  de: {
    translation: translationDE,
    primark: translationPrimarkDE
  },
  el: {
    translation: translationEL,
  },
  en: {
    translation: translationEN,
    circlek: translationCirclekEN,
    pandora: translationPandoraEN
  },
  es: {
    translation: translationES,
    primark: translationPrimarkES
  },
  et: {
    translation: translationET,
    circlek: translationCirclekET
  },
  fi: {
    translation: translationFI,
  },
  fr: {
    translation: translationFR,
    primark: translationPrimarkFR
  },
  hu: {
    translation: translationHU,
    primark: translationPrimarkHU
  },
  ie: {
    translation: translationIE,
  },
  it: {
    translation: translationIT,
    primark: translationPrimarkIT
  },
  ja: {
    translation: translationJA,
  },
  lt: {
    translation: translationLT,
    circlek: translationCirclekLT
  },
  lv: {
    translation: translationLV,
    circlek: translationCirclekLV
  },
  nl: {
    translation: translationNL,
    primark: translationPrimarkNL
  },
  no: {
    translation: translationNO,
  },
  pl: {
    translation: translationPL,
    primark: translationPrimarkPL,
    circlek: translationCirclekPL
  },
  pt: {
    translation: translationPT,
    primark: translationPrimarkPT,
  },
  ro: {
    translation: translationRO,
    primark: translationPrimarkRO
  },
  sk: {
    translation: translationSK,
    primark: translationPrimarkSK,
  },
  sl: {
    translation: translationSL,
    primark: translationPrimarkSL
  },
  sv: {
    translation: translationSV,
    circlek: translationCirclekSV
  },
  th: {
    translation: translationTH,
  },
  tr: {
    translation: translationTR,
  },
  zh: {
    translation: translationZH,
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    ns: ['translation','primark','circlek','pandora'], // for those using custom translations, you will need to add your new namespace here
    defaultNS: "translation",
    fallbackNS: "translation",
    lng: 'en',
    fallbackLng: 'en', // use en if detected lng is not available

    keySeparator: '.', // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    debug: false,
  });

export default i18n;
